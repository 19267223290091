import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";

import Anchor from "components/elements/Anchor/Anchor";
import SignUpForm from "components/elements/SignUpForm/SignUpForm";
import useCookie from "utils/useCookie";

const SignUp: FunctionComponent = () => {
  const [_, setSignedUp] = useCookie("signedUp");

  return (
    <>
      <Anchor id="sign-up" />
      <SignUpForm
        thankYouMessage="We'll be in touch."
        onSubmit={(): void => setSignedUp("true")}
      >
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            display="inline"
            color="primary"
            style={{ fontWeight: 800 }}
          >
            {"Sign up"}
          </Typography>
          <Typography variant="h6" display="inline">
            {" to get early access to the marketplace."}
          </Typography>
        </div>
      </SignUpForm>
    </>
  );
};

export default SignUp;
