import { createGlobalStyle } from "styled-components";
import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core/styles";
import "react-image-gallery/styles/css/image-gallery.css";

import { hexToRgb, addAlpha } from "./StyleHelper";

const primary = "#08C5BF";
const secondary = "#C60C11";
const borderRadius = "4px";

export const GlobalStyles = createGlobalStyle`

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}

/*
  Custom styles for galery size
  https://github.com/xiaolin/react-image-gallery/issues/69
  https://stackoverflow.com/a/61580097/2771889
*/

.image-gallery {
  min-height: 300px;
  max-height: 700px;
}

.image-gallery-slide img {
  height: 300px;
}

.fullscreen .image-gallery-slide img {
  height: 100vh;
}
`;

export const muiTheme = (options?: ThemeOptions): Theme => {
  return createMuiTheme({
    layout: {
      headerHeight: "70px",
      footerMinHeightDesktop: "70px",
      footerMinHeightMobile: "116px",
      signupMinHeight: "150px",
    },
    elements: {
      borderRadius,
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
    overrides: {
      MuiListItem: {
        root: {
          "&$selected": {
            backgroundColor: "transparent",
            border: `2px solid ${primary}`,
            borderRadius,
            "&:hover": {
              backgroundColor: addAlpha(hexToRgb(primary), 0.08),
            },
          },
        },
        button: {
          "&:hover": {
            backgroundColor: addAlpha(hexToRgb(primary), 0.08),
          },
        },
      },
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: addAlpha(hexToRgb(primary), 0.08),
          },
        },
      },
      MuiButton: {
        containedPrimary: {
          "&:hover": {
            backgroundColor: addAlpha(hexToRgb(primary), 0.5),
          },
        },
      },
    },
    ...options,
  });
};
