import React, { FunctionComponent } from "react";
import CookieConsent from "react-cookie-consent";

import Footer from "components/App/Layout/Footer/Footer";
import Header from "components/App/Layout//Header/Header";
import Background from "components/App/Layout//Background/Background";
import SignUp from "components/App/Layout/SignUp/SignUp";

import { FooterWrapper, HeaderWrapper, Main } from "./Layout.style";

const Layout: FunctionComponent = (props) => {
  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>

      <Background>
        <Main>{props.children}</Main>
      </Background>

      <CookieConsent>
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <FooterWrapper>
        <SignUp />
        <Footer />
      </FooterWrapper>
    </>
  );
};

export default Layout;
