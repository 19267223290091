import React, { FunctionComponent } from "react";

import ShowOnMobile from "components/elements/ShowOnMobile/ShowOnMobile";
import HideOnMobile from "components/elements/HideOnMobile/HideOnMobile";

import {
  DesktopBackgroundLeft,
  DesktopBackgroundRight,
  MobileBackground,
  MobileBackgroundOppacity,
  DesktopBackgroundTransformFix,
} from "./Background.style";

const Background: FunctionComponent = (props) => {
  return (
    <>
      <HideOnMobile>
        <DesktopBackgroundLeft>
          <DesktopBackgroundRight>
            <DesktopBackgroundTransformFix>
              {props.children}
            </DesktopBackgroundTransformFix>
          </DesktopBackgroundRight>
        </DesktopBackgroundLeft>
      </HideOnMobile>
      <ShowOnMobile>
        <MobileBackground>
          <MobileBackgroundOppacity>{props.children}</MobileBackgroundOppacity>
        </MobileBackground>
      </ShowOnMobile>
    </>
  );
};

export default Background;
