import React, { FunctionComponent } from "react";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { HashLink } from "react-router-hash-link";

import logoImg from "resources/logo.svg";
import LinkNoDecor from "components/elements/LinkNoDecor/LinkNoDecor";

import { ButtonWrapper, HeaderWrapper, LogoImage } from "./Header.style";
import HideOnMobile from "../../../elements/HideOnMobile/HideOnMobile";

const Header: FunctionComponent = () => {
  return (
    <header>
      <HeaderWrapper
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <LinkNoDecor to="/" color="inherit">
          <Box display="flex" alignItems="center">
            <Box p={2}>
              <LogoImage src={logoImg} />
            </Box>
            <Typography variant="h5">Componently</Typography>
          </Box>
        </LinkNoDecor>
        <ButtonWrapper>
          <HideOnMobile>
            <Box p={2}>
              <Button href="https://vendor.componently.com/" variant="text">
                For vendors
              </Button>
            </Box>
          </HideOnMobile>
          <Link
            style={{
              textDecoration: "none",
            }}
            smooth
            to="#sign-up"
            component={HashLink}
          >
            <Box p={2}>
              <Button variant="contained" color="primary">
                Sign up
              </Button>
            </Box>
          </Link>
        </ButtonWrapper>
      </HeaderWrapper>
    </header>
  );
};

export default Header;
