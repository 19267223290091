import React, { FunctionComponent, useState } from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

import addEmailToMarketingList from "utils/addEmailToMarketingList";

import { trackEvent } from "utils/usePageTracking/usePageTracking";
import { SignUpRootGrid } from "./SignUpForm.style";

const Form: FunctionComponent<{
  submitted: boolean;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  thankYouMessage: string;
}> = ({ submitted, handleSubmit, thankYouMessage }) => {
  return (
    <form
      target="transFrame"
      action="https://api.staticforms.xyz/submit"
      method="post"
      onSubmit={handleSubmit}
    >
      <Typography
        variant="h6"
        align="center"
        style={submitted ? {} : { display: "none" }}
      >
        {thankYouMessage}
      </Typography>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        style={submitted ? { display: "none" } : {}}
      >
        <Grid item xs={8}>
          <FormControl>
            <InputLabel>Email</InputLabel>
            <Input id="email-form-input" name="email" type="email" required />
            <FormHelperText id="my-helper-text">
              {submitted ? thankYouMessage : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" type="submit" color="primary">
            Send
          </Button>
        </Grid>
      </Grid>
      <Input
        type="hidden"
        name="accessKey"
        value="fa9da396-eba1-47d8-a50b-f0400a2be24b"
      />
      <iframe
        name="transFrame"
        id="transFrame"
        style={{ display: "none" }}
        title="transFrame"
      />
    </form>
  );
};

const SignUpForm: FunctionComponent<{
  onSubmit: () => void;
  message?: string;
  thankYouMessage: string;
}> = (props) => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    setSubmitted(true);
    // TODO: https://trello.com/c/TbyzHWH2/205-use-formik-for-forms-clean-up-after-submission
    // eslint-disable-next-line
    // @ts-ignore
    addEmailToMarketingList(event.target[0].value);
    trackEvent({
      category: "User",
      action: "userSignedUp",
      label: window.location.pathname,
    });
    if (props.onSubmit) {
      props.onSubmit();
    }
  };

  return (
    <SignUpRootGrid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        {props.message ? (
          <Typography variant="h6" align="center">
            {props.message}
          </Typography>
        ) : (
          props.children
        )}
      </Grid>
      <Grid item>
        <Form
          submitted={submitted}
          handleSubmit={handleSubmit}
          thankYouMessage={props.thankYouMessage}
        />
      </Grid>
    </SignUpRootGrid>
  );
};

export const PaperForm: FunctionComponent<{
  onSubmit: () => void;
  message?: string;
  thankYouMessage: string;
}> = ({ message, onSubmit, thankYouMessage }) => {
  return (
    <Paper>
      <SignUpForm
        message={message}
        thankYouMessage={thankYouMessage}
        onSubmit={onSubmit}
      />
    </Paper>
  );
};

export default SignUpForm;
