import React, { FunctionComponent, Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";

import Layout from "components/App/Layout/Layout";
import Loading from "components/App/Loading/Loading";
import Head from "components/App/Head/Head";
import usePageTracking from "utils/usePageTracking/usePageTracking";

const Home = lazy(() => import("components/App/Home/Home"));
const Category = lazy(() => import("components/App/Category/Category"));
const Vendor = lazy(() => import("components/App/Vendor/Vendor"));
const Legal = lazy(() => import("components/App/Legal/Legal"));
const SignUp = lazy(() => import("components/App/SignUp/SignUp"));

const App: FunctionComponent = () => {
  usePageTracking();

  return (
    <>
      <Head />
      <Layout>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/categories/:slug" component={Category} />
            <Route
              exact
              path="/vendors/:vendorSlug/:packageSlug/signup"
              component={SignUp}
            />
            <Route exact path="/vendors/:slug" component={Vendor} />

            <Route exact path="/terms-and-conditions" component={Legal} />
            <Route exact path="/data-privacy" component={Legal} />
            <Route exact path="/imprint" component={Legal} />
            <Route exact path="/legal" component={Legal} />
            <Route path="/" />
          </Switch>
        </Suspense>
      </Layout>
    </>
  );
};

export default App;
