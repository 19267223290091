import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import localLog from "utils/localLog";

type Event = { category: string; action: string; label: string };

const localEnv = (): boolean => {
  return window.location.href.includes("localhost");
};

const ciEnv = (): boolean => {
  return window.location.href.includes("env=CI");
};

export function trackEvent(event: Event): void {
  localLog("Tracking event", event);
  if (!(localEnv() || ciEnv())) {
    window.analytics.track(event.action, {
      category: event.category,
      label: event.label,
    });
  }
}

const usePageTracking = (): void => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!(localEnv() || ciEnv())) {
      window.analytics.load("djYGjaGl5LmCqGTKoCjYuW7FFD6OIlAU");
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    localLog("Tracking page view", location.pathname + location.search);

    if (initialized) {
      window.analytics.page("Page viewed", {
        title: "Componently",
        url: `https://www.componently.com${location.pathname}`,
        path: location.pathname,
        search: location.search,
      });
    }
  }, [initialized, location]);
};

export default usePageTracking;
