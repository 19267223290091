import styled from "styled-components";

const Anchor = styled.a((props) => {
  return {
    display: "block",
    position: "relative",
    top: `calc(-${props.theme.layout.headerHeight} - ${props.theme.spacing(
      2
    )}px)`,
    visibility: "hidden",
  };
});

export default Anchor;
