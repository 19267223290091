const addEmailToMarketingList = (email: string): Promise<Response> => {
  const currentPath = window.location.pathname;
  const body = {
    email,
    source: currentPath,
  };

  const url =
    "https://qj9up59uk1.execute-api.eu-central-1.amazonaws.com/dev/register";

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
};

export default addEmailToMarketingList;
