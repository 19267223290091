import styled from "styled-components";

import DesktopLeft from "resources/background-desktop-left.jpg";
import Mobile from "resources/background-mobile.jpg";

const desktopBackgroundStyles = {
  backgroundImage: `url(${DesktopLeft})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "40% auto",
  backgroundPosition: "left top",
};

export const DesktopBackgroundLeft = styled.div(() => {
  return desktopBackgroundStyles;
});

export const DesktopBackgroundRight = styled.div(() => {
  return {
    ...desktopBackgroundStyles,
    "-webkit-transform": "scaleX(-1)",
  };
});

export const DesktopBackgroundTransformFix = styled.div(() => {
  return {
    "-webkit-transform": "scaleX(-1)",
  };
});

export const MobileBackground = styled.div(() => {
  return {
    backgroundImage: `url(${Mobile})`,
    backgroundSize: "100%",
    backgroundPosition: "center top",
  };
});

export const MobileBackgroundOppacity = styled.div(() => {
  return {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  };
});
