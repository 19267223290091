import { Helmet } from "react-helmet";
import React, { FunctionComponent } from "react";

const Head: FunctionComponent = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Marketplace for Web Application Features"
      />
      <meta
        name="keywords"
        content="Componently, Marketplace, React, Feature"
      />
      <meta name="author" content="Componently" />
      <title>Componently</title>
    </Helmet>
  );
};

export default Head;
