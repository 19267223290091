import { useState } from "react";
import Cookies from "js-cookie";

const useCookie = (
  key: string
): [string | undefined, (value: string) => void] => {
  const [cookie, setInnerValue] = useState(Cookies.get(key));

  const setValue = (
    value: string,
    options?: Cookies.CookieAttributes
  ): void => {
    setInnerValue(value);
    Cookies.set(key, value, options);
  };

  return [cookie, setValue];
};

export default useCookie;
